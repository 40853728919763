<template>
  <v-container fluid>
    <v-sheet class="d-flex mx-auto py-4" min-height="80vh" :width="$vuetify.breakpoint.mobile ? '92vw' : '80vw'">
      <v-row no-gutters>
        <v-col cols="12" md="3" class="px-2 py-4">
          <v-row no-gutters justify="center">
            <v-col cols="12" class="text-center">
              <v-icon size="125" color="activeButton"> mdi-account-school-outline </v-icon>
            </v-col>
            <v-col cols="12">
              <p class="text-h5 mb-0 text--secondary text-center font-weight-medium">{{ currentUser.name }}</p>
            </v-col>
            <v-col cols="12">
              <p class="text-h6 mb-0 text--secondary text-center font-weight-medium">{{ currentUser.organizationName }}</p>
            </v-col>
          </v-row>
          <div class="d-block mt-8 px-4">
            <v-menu top close-on-content-click>
              <template v-slot:activator="{ on, attrs }">
                <r-btn class="mb-3" block v-bind="attrs" v-on="on">Lektionsplan</r-btn>
              </template>
              <v-list class="my-0 pt-2 pb-0">
                <v-list-item>
                  <r-btn class="mb-2" @click="downloadStudentEventLog" block> Hent lektionsplan </r-btn>
                </v-list-item>
                <v-list-item>
                  <r-btn @click="sendStudentEventLogToUserMail" block> Send lektionsplan til min mail </r-btn>
                </v-list-item>
              </v-list>
            </v-menu>
            <r-btn
              class="mb-3"
              block
              :loading="updatingUserAssignment"
              @click="userAssignment"
              v-if="currentUser.staffId == null && hasAccess('AssignSelfStudent')"
            >
              Tag Elev
            </r-btn>
            <r-btn class="mb-3" block @click="openNotificationDialog">Send notifikation</r-btn>
            <r-btn class="mb-3" @click="UserCalender" block>
              <v-icon left>mdi-calendar-account</v-icon>
              Se elev kalender
            </r-btn>

            <r-btn v-if="hasAccess('ModifyStudents')" class="mb-3" @click="editUser" block>
              <v-icon left>mdi-pencil</v-icon>
              Rediger elev
            </r-btn>
            <v-menu top close-on-content-click v-if="hasAccess('ModifyStudents')">
              <template v-slot:activator="{ on, attrs }">
                <r-btn class="mb-3" block v-bind="attrs" v-on="on">Kodeord</r-btn>
              </template>
              <v-list class="my-0 pt-2 pb-0">
                <v-list-item>
                  <r-btn class="mb-3" @click="sendPasswordChangeEmail" block>
                    <v-icon left>mdi-email-arrow-right-outline</v-icon>
                    Nulstil kodeord
                  </r-btn>
                </v-list-item>
                <v-list-item>
                  <r-btn v-if="isAdmin" class="mb-3" @click="$refs.ChangePasswordRef.changePassword(currentUser, true)" block>
                    <v-icon left>mdi-lock-reset</v-icon>
                    Skift kodeord
                  </r-btn>
                </v-list-item>
              </v-list>
            </v-menu>

            <!-- <r-btn
              class="mb-3"
              block
              :loading="updatingUserAssignment"
              @click="userAssignment"
              v-if="hasAccess('ModifyStudents') || currentUser.staffId == user.id"
            >
              Frigiv elev
            </r-btn> -->

            <r-btn class="mb-3" block @click="finishStudent" v-if="hasAccess('ModifyStudents') || currentUser.staffId == user.id">
              Afslut elev
            </r-btn>

            <r-btn class="mb-3" block :loading="updatingState" @click="deleteStudent" v-if="hasAccess('ModifyStudents')" delete>
              <v-icon left>mdi-cancel</v-icon>
              Slet
            </r-btn>
          </div>
        </v-col>
        <v-divider vertical inset />
        <v-col cols="12" md="4" class="px-2 py-4 text-subtitle-1">
          <v-row no-gutters class="text-left px-4"> <p class="text-h6 text--secondary font-weight-medium my-auto">Elev Detaljer</p> </v-row>
          <div class="px-4">
            <v-row class="px-2 my-1">
              <v-col>Navn:</v-col>
              <v-col cols="auto">{{ currentUser.name }}</v-col>
            </v-row>
            <v-divider />
            <v-row v-if="currentUser.email" class="px-2 my-1">
              <v-col>Email:</v-col>
              <v-col cols="auto">{{ currentUser.email }}</v-col>
            </v-row>
            <v-divider />
            <v-row class="px-2 my-1">
              <v-col>Telefon nummer:</v-col>
              <v-col cols="auto">{{ currentUser.phoneNumber }}</v-col>
            </v-row>
            <v-divider />
            <v-row class="px-2 my-1">
              <v-col>Køreskole:</v-col>
              <v-col cols="auto">{{ currentUser.organizationName }}</v-col>
            </v-row>
            <div v-if="isAdmin">
              <v-divider />
              <v-row v-if="currentUser.staffName" class="px-2 my-1">
                <v-col>Kørelærer:</v-col>
                <v-col cols="auto">{{ currentUser.staffName }}</v-col>
              </v-row>
            </div>
            <v-divider />
            <v-row v-if="studentEvents" class="px-2 my-1">
              <v-col>Lektionsplan:</v-col>
              <v-col cols="auto">{{ moduleListName }}</v-col>
            </v-row>
            <v-divider />
            <!-- <v-row v-if="currentUser.theoryTeamId" class="px-2 my-1">
              <v-col>Teori Hold:</v-col>
              <v-col cols="auto">{{ currentUser.theoryTeamName }}</v-col>
            </v-row> -->
            <!-- <v-divider v-if="currentUser.theoryTeamId" /> -->
          </div>
        </v-col>
        <v-divider vertical inset />
        <v-col cols="12" md="5" class="px-2 py-4">
          <div class="d-flex flex-column" style="height: 74vh">
            <div v-if="studentEvents" class="text-left px-4">
              <p class="text-h6 text--secondary font-weight-medium my-auto">Lektionsplan - {{ moduleListName }}</p>
            </div>
            <div v-if="studentEvents && !unCompleted" style="overflow-y: auto">
              <div v-for="(item, index) in studentEvents.moduleList.modules" :key="index" class="mb-4 mt-1 px-4">
                <v-card elevation="1" :color="item.instructorSignatur && item.studentSignature ? 'white' : 'grey lighten-3'" class="background">
                  <v-card-title class="d-block pb-0">
                    <v-row no-gutters>
                      <v-col cols="auto" class="d-flex text-left">
                        <p class="my-auto">{{ item.name }}</p>
                      </v-col>
                      <v-spacer></v-spacer>
                      <v-col class="text-right">
                        <p class="text-body-2 my-auto">
                          {{ parseLessonType(item.lessonType) }}
                        </p>
                      </v-col>
                    </v-row>
                    <span class="text-subtitle-1 text-left mb-2" style="white-space: pre-line">{{ item.eventComment }}</span>
                  </v-card-title>
                  <v-card-text class="text-subtitle-1">
                    {{ item.instructorSignatur && item.studentSignature ? 'Gennemført' : 'Ikke gennemført' }}
                  </v-card-text>
                </v-card>
              </div>
            </div>
            <div v-else>
              <v-card-title>Ingen lektioner gennemført</v-card-title>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-sheet>

    <create-edit-user ref="CreateEditUserRef" @user-updated="getUserDetails" />

    <v-dialog v-model="showNotifications" max-width="650" persistent>
      <v-card class="pa-0 pb-2">
        <v-card-title>Send mobil-notifikation til {{ currentUser.name }}</v-card-title>
        <v-card-text>
          <v-text-field hide-details outlined v-model="notificationObject.subject" label="Emne" class="mb-2" />
          <v-textarea hide-details outlined v-model="notificationObject.body" label="Tekst" />
        </v-card-text>
        <v-card-actions class="px-6">
          <r-btn @click="showNotifications = false">Luk dialog</r-btn>
          <r-btn
            class="ml-auto"
            :disabled="customNotificationInvalid"
            @click="sendCustomNotification(notificationObject.subject, notificationObject.body)"
          >
            Send
          </r-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <change-password-dialog ref="ChangePasswordRef" />
    <confirmation-dialog ref="confirmationDialogRef" />
  </v-container>
</template>

<script>
import apiService from '@/services/apiService';
import CreateEditUser from '@/components/dialogs/CreateEditUser.vue';
import ChangePasswordDialog from '@/components/ChangePasswordDialog.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'User-Details',
  components: {
    CreateEditUser,
    ChangePasswordDialog,
  },
  props: {
    UserId: String,
  },
  watch: {
    UserId: {
      immediate: true,
      handler(val) {
        if (val) {
          this.getUserDetails();
        }
      },
    },
  },
  data: () => ({
    notificationObject: { subject: '', body: '' },
    showNotifications: false,
    updatingState: false,
    studentEvents: null,
    updatingUserAssignment: false,
    loading: false,
    currentUser: {},
    studentListHeaders: [
      {
        text: 'Navn',
        value: 'name',
      },
      {
        text: 'Email',
        value: 'email',
      },
      {
        text: 'Tlf. nummer',
        value: 'phoneNumber',
      },
    ],
  }),
  computed: {
    ...mapGetters(['user', 'isStaff', 'isAdmin', 'currentOrgId', 'hasAccess']),
    studentIsAssigned() {
      return this.currentUser.staffId !== null;
    },
    customNotificationInvalid() {
      return this.notificationObject.subject && this.notificationObject.body ? false : true;
    },
    unCompleted() {
      if (this.studentEvents?.moduleList.modules.some(x => x.studentSignature != null && x.instructorSignatur != null)) {
        return false;
      }
      return true;
    },
    moduleListName() {
      return this.studentEvents?.moduleList?.name;
    },
  },
  methods: {
    parseLessonType(type) {
      switch (type) {
        case 1:
          return 'Teori';
        case 2:
          return 'Praktisk';
        case 3:
          return 'Prøve';
        case 4:
          return 'Repetition 7.1 - 7.21';
        default:
          return 'None';
      }
    },
    async sendCustomNotification(subject, body) {
      if (!this.customNotificationInvalid) {
        this.notificationObject = { subject: '', body: '' };
        let request = {
          Target: [this.currentUser.id],
          Subject: subject,
          Body: body,
        };
        await apiService.sendCustomNotification(this.currentUser.organizationId, request);
      }
    },
    async deleteStudent() {
      let dialogResponse = await this.$refs.confirmationDialogRef.showDialog({
        title: 'Slet elev',
        studentName: this.currentUser.name,
        body: `Ønsker du at slette denne elev?`,
        okBtn: 'Slet',
      });
      if (dialogResponse) {
        this.updatingState = true;
        await apiService.deactivateStudent(this.currentUser.id);
        this.updatingState = false;
        this.$router.push({ name: 'students' });
      }
      this.$refs.confirmationDialogRef.hideDialog();
    },
    async finishStudent() {
      apiService.finishStudent(this.currentUser.id);
    },
    async userAssignment() {
      this.updatingUserAssignment = true;

      if (this.studentIsAssigned) {
        await apiService.releaseStudent(this.currentUser.id);
        this.$store.commit('alert', {
          show: true,
          title: `Bruger tildeling`,
          color: 'success',
          message: `Eleven ${this.currentUser.name} er nu frigivet`,
        });
      } else {
        await apiService.takeStudent(this.currentUser.id);
        this.$store.commit('alert', {
          show: true,
          title: `Bruger tildeling`,
          color: 'success',
          message: `Eleven ${this.currentUser.name} er nu tildelt dig`,
        });
      }

      await this.getUserDetails();
      this.updatingUserAssignment = false;
    },
    openNotificationDialog() {
      this.showNotifications = true;
    },
    async getUserDetails() {
      this.notificationObject = { subject: '', body: '' };
      this.currentUser = await apiService.getStudentById(this.UserId);
      this.getStudentModuleStatus();
    },
    async getStudentModuleStatus() {
      this.studentEvents = await apiService.getStudentModuleStatus(this.currentUser.id);
    },
    editUser() {
      this.$refs.CreateEditUserRef.editUser(this.currentUser.id, false);
    },
    UserCalender() {
      this.$router.push({ name: 'Calendar', params: { UserId: this.currentUser.id, UserName: this.currentUser.name } });
    },
    async saveChanges() {
      this.loading = true;
      await apiService.updateUser(this.currentUser).then(() => {
        this.getUserDetails();
        this.loading = false;
      });
    },
    async sendPasswordChangeEmail() {
      await apiService.resetPassword(this.currentUser.email);
      this.$store.commit('alert', {
        show: true,
        title: 'Email sendt',
        color: 'success',
        message: `Email Kodeord-reset request er blevet sendt til  ${this.currentUser.email}`,
      });
    },
    async downloadStudentEventLog() {
      const fileURL = window.URL.createObjectURL(new Blob([await apiService.getStudentEventLogPDF(this.currentUser.id)]));
      const fileLink = document.createElement('a');
      fileLink.href = fileURL;
      fileLink.setAttribute('download', `${this.currentUser.name} - Lektionsplan.pdf`);
      document.body.appendChild(fileLink);

      fileLink.click();
    },
    sendStudentEventLogToUserMail() {
      apiService.sendStudentEventLog(this.currentUser.id);
    },
  },
};
</script>

<style lang="scss">
.NoHover tr:hover,
.NoHover:before {
  background-color: transparent !important;
}
</style>
